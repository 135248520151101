import React from "react";
import { Link } from "gatsby";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import styles from "../styles/work.module.css";
import pilcrowPic from "../assets/images/pilcrow.png";
import mapPic from "../assets/images/map.png";
import pendulumPic from "../assets/images/pendulumPic.png";
import nbodyPic from "../assets/images/nbody.png";

export default () => {
    return (
        <div className={styles.workContainer}>
            <div className={styles.projectsHeading}>Projects</div>
            <Row className={styles.rowEqHeight}>
                <Col xs={12} md={6} className={styles.colEqHeight + " px-1"}>
                    <Link to="/all">
                        <img className={styles.projectTile} src={mapPic}></img>
                    </Link>
                    <Link to="/pendulums/content">
                        <img className={styles.projectTile} src={pendulumPic}></img>
                    </Link>
                </Col>
                <Col xs={12} md={6} className={styles.colEqHeight + " px-1"}>
                    <a href="http://54.183.223.106">
                        <img className={styles.projectTile} src={pilcrowPic}></img>
                    </a>
                    <a href="/nbodysim.html">
                        <img className={styles.projectTile} src={nbodyPic}></img>
                    </a>
                </Col>
            </Row>
        </div>
    )
}